import React from 'react';
import loadingImage from '../assets/images/kallan-signature-animation.gif';
import '../assets/style/Loading.scss';

function Loading() {
	return (
		<div className="siteloader">
			<img alt="loading" width="600" src={loadingImage} />
		</div>
	)
}

export default Loading