import { useLayoutEffect } from 'react';
import { useController } from 'react-scroll-parallax';

const ParallaxCache = () => {
    const { parallaxController } = useController();

    useLayoutEffect(() => {
        window.requestAnimationFrame(() => {
            parallaxController.update()
        })
    })

    return null;
};


export default ParallaxCache;