import React from 'react';
import SrcsetImage from './SrcsetImage';

function CaseImage(props) {
	const { data, width, compressImage } = props;

	return (
		<div className={width === 'container' ? 'inner' : width === 'fullbleed' ? 'fullbleed' : 'bleed'}>
			<SrcsetImage
				data={data}
				caption={true}
				bleed={width === 'container' ? false : true}
				compress={compressImage}
			/>
		</div>
	)
}

export default CaseImage;