import { useEffect } from 'react';

function ScrollToTop() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(()=>{
            window.scrollTo(0, 0);
        }, 50)
    });
    return null
}

export default ScrollToTop;

