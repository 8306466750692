import React from 'react';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../utils/prismic';

function TextBlock(props) {
	const { text, bg, alignment, maxWidth } = props;
	
	return (
		<div
			className="case_block_text"
			data-align={alignment || 'left'}
			data-text-align={alignment || "left"}
			data-bg-color={bg === "#000000" ? "white" : "black"}
			style={{
//				color: bg === "#000000" ? "white" : "black",
				maxWidth: maxWidth ? maxWidth + 'px' : null
			}}
		>
			{RichText.render(text, htmlSerializer)}
		</div>
	)
}

export default TextBlock