import React from 'react';
import ReactPlayer from 'react-player';

function SingleVideo(props) {
	const { url } = props;
	
	return (
			<ReactPlayer
				url={url}
				volume={0}
				muted
				loop
				playing
				width="100%"
				height="100%"
				playsinline={true}
			/>
	)
}

export default SingleVideo;	