import React from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import { MultilineReveal } from "react-text-reveal";
import { InView } from 'react-intersection-observer';
import { BrowserView, MobileView } from "react-device-detect";
import Fade from 'react-reveal/Fade';
import SEO from '../components/SEO';
import Header from '../components/Header';
import WorkList from '../components/WorkList';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import '../assets/style/Work.scss';

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        locale: false,
        filter: 'all'
    }
    this.workListRef = React.createRef();
  }

  componentDidMount() {
    if (!!this.props.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.locale && !!prevProps.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }
  }

  render() {
    const { filter, locale } = this.state;
    const { lastLocation } = this.props;
    const { workPage, cases, work, nzWork } = this.props.data;
    const { intro, services } = workPage;
    const workList = locale === "PACIFIC" ? nzWork : work;

    return (
      <div className="work">
        {lastLocation && lastLocation.pathname === '/' && <ScrollToTop />}
        <SEO
          title="Work"
          description={intro}
        />
        <Header />

        <BrowserView>
            <InView className="hero inner" as="div" triggerOnce onChange={
                (inView, entry) => {
                  if (inView) this.setState({heroInView: true})
                }
              }>
              <h1 className="bravo desktop">
                <MultilineReveal
                    animateOpacity={true}
                    canPlay={this.state.heroInView}
                    copy={["We use design and creative","methods to make strategies","and concepts come alive."]}
                    direction={"bottom"}
                    duration={700} // ms
                    ease={"cubic-bezier(0.12,0.66,0.29,0.96)"}
                    offset={"100px"}
                    multilineMasking={true}
                    multilineOffsetDelay={75} // ms
                />
              </h1>
                <Fade bottom duration={1200} distance="10px" delay={150}><h2 className="services">{services.map((s, i) => <div key={i}>{s.service_item}</div>)}</h2></Fade>
            </InView>
        </BrowserView>
        <MobileView>
            <Fade>
            <div className="hero inner">
                <h1>{intro}</h1>
            </div>
            </Fade>
        </MobileView>
        <WorkList
            cases={cases}
            work={workList}
            filter={filter}
            workListRef={this.workListRef}
        />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    locale: state.locale
  }
}

export default connect(mapStateToProps)(withLastLocation(Work));
