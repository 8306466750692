import React from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import { MultilineReveal } from "react-text-reveal";
import { InView } from 'react-intersection-observer'
import { BrowserView, MobileView } from "react-device-detect";
import Fade from 'react-reveal/Fade';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Fader from '../components/Fader';
import ScrollToTop from '../components/ScrollToTop';
import SiteImage from '../components/ImageProcessor/SiteImage';
import { handleLineBreaks } from '../utils/prismic';
import '../assets/style/Ux.scss';

class UX extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        locale: false,
        pageReady: false
    }
  }

  componentDidMount() {
    if (!!this.props.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }
    this.timeoutId = setTimeout(function () {
        this.setState({
            pageReady: true
        });
    }.bind(this), 30);
      
  }

  componentDidUpdate(prevProps) {
    if (!this.state.locale && !!prevProps.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }

  }

  render() {
    const { uxPage } = this.props.data;
    const { intro,
            description,
            section_title,
            services_title,

            photo1,
            set_1_subtitle,
            set_1_title,
            set_1_description,

            photo2,
            set_2_subtitle,
            set_2_title,
            set_2_description,
       
            video1_url,
            video2_url,
           
            photo3,
            set_3_title,
            set_3_description,
           
            photo4,
            set_4_title,
            set_4_description,
           
            photo5,
            set_5_subtitle,
            set_5_title,
            set_5_description,
           
            photo6,
            set_6_subtitle,
            set_6_title,
            set_6_description,
           
            photo7,
            set_7_subtitle,
            set_7_title,
            set_7_description,
           
            services_1_title,
            services_1_list,
            services_2_title,
            services_2_list,
            services_3_title,
            services_3_list,
           
            contact_title,
            contact_description,
            contact_person,
            contact_role,
            contact_email,
            contact_phone,
           
            info_1_title,
            info_1_list,
            info_2_title,
            info_2_list,
            info_3_title,
            info_3_list,
           

    } = uxPage;
    const { lastLocation } = this.props;
    const { pageReady } = this.state;
    const winWidth = window.innerWidth;

    return (
      <div className={"ux en " + (pageReady? "loaded":"")}>
        {lastLocation && !lastLocation.pathname.includes('work') && <ScrollToTop />}
        <SEO
          title="UX"
          description={intro}
        />
        <Header />
              
        <div className="pagecontainer">
        
        <section id="hero">
        <BrowserView>
            <InView className="hero" as="div" triggerOnce onChange={
                (inView, entry) => {
                  if (inView) this.setState({heroInView: true})
                }
              }>
              <h1 className="desktop">
                <MultilineReveal
                    animateOpacity={true}
                    canPlay={this.state.heroInView}
                    copy={intro.split('\n').map((item, key) => {
                      return item;
                    })}
                    direction={"bottom"}
                    duration={700} // ms
                    ease={"cubic-bezier(0.12,0.66,0.29,0.96)"}
                    offset={"100px"}
                    multilineMasking={true}
                    multilineOffsetDelay={75} // ms
                />
              </h1>
                <Fade bottom duration={1200} distance="10px" delay={150}><div className="description">{description}</div></Fade>
            </InView>
        </BrowserView>
        <MobileView>
            <Fade>
            <div className="hero inner">
                <h1>{intro}</h1>
                <div className="description">{description}</div>
            </div>
            </Fade>
        </MobileView>
        </section>
        
        <Fader>
        <section className="set">

            <div className="item full">
                <span className="subtitle">{handleLineBreaks(set_1_subtitle)}</span>
                <SiteImage key={photo1.url} data={photo1} />
                <div className="description">
                    <h4>{handleLineBreaks(set_1_title)}</h4>
                    <p>{handleLineBreaks(set_1_description)}</p>
                </div>
            </div>
            <div className="item full">
                <span className="subtitle">{handleLineBreaks(set_2_subtitle)}</span>
                <SiteImage key={photo2.url} data={photo2} />
                <div className="description">
                    <h4>{handleLineBreaks(set_2_title)}</h4>
                    <p>{handleLineBreaks(set_2_description)}</p>
                </div>
            </div>
            <div className="item half">
                <video autoPlay muted playsInline loop>
                    <source src={video1_url.url} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    <img src={photo3.url} alt="Your browser does not support html5 video" />
                </video>
                <div className="description">
                    <h4>{handleLineBreaks(set_3_title)}</h4>
                    <p>{handleLineBreaks(set_3_description)}</p>
                </div>
            </div>
            <div className="item half">
                <video autoPlay muted playsInline loop>
                    <source src={video2_url.url} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    <img src={photo4.url} alt="Your browser does not support html5 video" />
                </video>
                <div className="description">
                    <h4>{handleLineBreaks(set_4_title)}</h4>
                    <p>{handleLineBreaks(set_4_description)}</p>
                </div>
            </div>

        </section>
        </Fader>


        <Fader>
        <section className="sectiontitle">
            <h2>{handleLineBreaks(section_title)}</h2>
        </section>
        </Fader>

        <section className="set">

            <div className="item full">
                <span className="subtitle">{handleLineBreaks(set_5_subtitle)}</span>
                <SiteImage data={photo5} />
                <div className="description">
                    <h4>{handleLineBreaks(set_5_title)}</h4>
                    <p>{handleLineBreaks(set_5_description)}</p>
                 </div>
            </div>
            <div className="item full">
                <span className="subtitle">{handleLineBreaks(set_6_subtitle)}</span>
                <SiteImage data={photo6} />
                <div className="description">
                    <h4>{handleLineBreaks(set_6_title)}</h4>
                    <p>{handleLineBreaks(set_6_description)}</p>
                 </div>
            </div>
            <div className="item full">
                <span className="subtitle">{handleLineBreaks(set_7_subtitle)}</span>
                <SiteImage data={photo7} />
                <div className="description">
                    <h4>{handleLineBreaks(set_7_title)}</h4>
                    <p>{handleLineBreaks(set_7_description)}</p>
                 </div>
            </div>

        </section>

        <Fader>
        <section className="services">
            
            <h3>{handleLineBreaks(services_title)}</h3>
                 
            <div className="servicelist">
                <div className="service">
                    <h5>{handleLineBreaks(services_1_title)}</h5>
                    <ul>
                        {services_1_list.map((s, i) => <li key={i}>{s.service_1_item}</li>)}
                    </ul>
                </div>
                <div className="service">
                    <h5>{handleLineBreaks(services_2_title)}</h5>
                    <ul>
                        {services_2_list.map((s, i) => <li key={i}>{s.service_2_item}</li>)}
                    </ul>
                </div>
                <div className="service">
                    <h5>{handleLineBreaks(services_3_title)}</h5>
                    <ul>
                        {services_3_list.map((s, i) => <li key={i}>{s.service_3_item}</li>)}
                    </ul>
                </div>
            </div>
        </section>
        </Fader>
                    
        <section className="contact">
            <div className="contact_container">
                <div className="contact_intro">{contact_title}</div>
                <div className="contact_separator"></div>
                <div className="contact_content">
                    <p>{handleLineBreaks(contact_description)}</p>
                    <p><span className="key_contact">{contact_person},</span> {contact_role}<br />
                    <a className="key_email"
                        href={`mailto:${contact_email}`}
                        ga-on="click"
                        ga-event-category="contact"
                        ga-event-action={`email-germany`}
                    >
                        {contact_email}
                    </a>
                    {winWidth < 700
                        ? <a href={`tel:${contact_phone.replace(/\s+/g, '')}`}>{contact_phone}</a>
                        : contact_phone
                    }
                    </p>
                </div>
            </div>
        </section>

        <section className="information">
             <div className="info">
                <h6>{handleLineBreaks(info_1_title)}</h6>
                <ul>
                    {info_1_list.map((s, i) => <li key={i}>{s.info_1_item}</li>)}
                </ul>
            </div>
            <div className="info">
                <h6>{handleLineBreaks(info_2_title)}</h6>
                <ul>
                    {info_2_list.map((s, i) => <li key={i}>{s.info_2_item}</li>)}
                </ul>
            </div>
            <div className="info">
                <h6>{handleLineBreaks(info_3_title)}</h6>
                <ul>
                    {info_3_list.map((s, i) => <li key={i}>{s.info_3_item}</li>)}
                </ul>
            </div>
        </section>

      </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    locale: state.locale
  }
}

export default connect(mapStateToProps)(withLastLocation(UX));