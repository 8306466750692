import { createSelector } from 'reselect';

const getFooterStudioContacts = state => state.data.footer.studio_contact;
const getFooterOfficeContacts = state => state.data.footer.office_contact;
const getStudios = state => state.data.studios;
const getStudioById = (state, studioId) => state.data.studios.find(studio => studio.id === studioId);
const getLocale = state => state.data.locale;

/**
 * Returns studios ordered by the users locale
 * @author Josh Smith <josh@batch.nz>
 * @return array An array of ordered studios
 */
export const getStudiosOrderedByLocale = createSelector(
  [getStudios, getLocale],
  (studios, locale) => {
    return _orderStudiosByLocale(studios, locale);
  }
);

/**
 * Returns footer studio contacts ordered by locale
 * @author Josh Smith <josh@batch.nz>
 * @return array
 */
export const getFooterStudioContactsOrderedByLocale = createSelector(
  [(state) => state, getFooterStudioContacts, getLocale],
  (state, studioContacts, locale) => {
    const studios = [];

    studioContacts.forEach(contact => {
      studios.push(getStudioById(state, contact.studio.id));
    });

    return _orderStudiosByLocale(studios, locale);
  }
);

/**
 * Returns footer office contacts by their prismic sort order
 * @author Josh Smith <josh@batch.nz>
 * @return array
 */
export const getFooterOfficeContactsOrderedBySort = createSelector(
  [getFooterOfficeContacts],
  (officeContacts) => {
    return officeContacts.sort((a,b) => a.footer_order > b.footer_order);
  }
);

/**
 * Returns ordered studios with the nearest one first
 * @author Josh Smith <josh@batch.nz>
 * @param  array    studios An array of studios
 * @param  string   locale  Studio locale
 * @return array
 */
function _orderStudiosByLocale(studios = [], locale = '') {
  const orderedStudios = [];
  const sortedStudios = studios.sort((a,b) => a.order > b.order);
  const nearestStudioIndex = sortedStudios.findIndex(studio => studio.tag === locale);

    // A special trick to wrap the studio order based on the nearest studio.
    // E.g., if the current locale is PACIFIC and has the order 2, the order of studios in the array will be [2,3,1].
    // If the current locale is MELBOURNE and has the order 3, the order of studios in the array will be [3,1,2].
    [
      ...sortedStudios.slice(nearestStudioIndex),
      ...sortedStudios.slice(0, nearestStudioIndex)
    ].forEach(studio => orderedStudios.push(studio));

    return orderedStudios;
}
