import React from 'react';
import { NavLink } from 'react-router-dom';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

function Four0Four(props) {

  
  return (
    <div className="four0four">
      <ScrollToTop />
      <SEO
        title="Page Not Found"
        description="We work with visionary companies and people to create the brands, products and user experiences of the future"
      />
    	<Header />
      <div className="error_page">
        <div className="inner">

          <h2 className="medium_font">Page not found</h2>
          <NavLink className="button black"
            title="home"
            to="/"
          >
            Return home
          </NavLink>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Four0Four;
