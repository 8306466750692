import React from 'react';	
import { Elements } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import Image from '../components/Image';

export const Prismic = require('prismic-javascript');
export const apiEndpoint = "https://kallan.cdn.prismic.io/api/v2";

export function normalizeTitleField(field) {
	return handleLineBreaks(field[0].text)
}

export function handleLineBreaks(field) {
	return field.split('\n').map((t, i, arr) =>
		<span key={i}>
      {t}
      {i < arr.length-1 && <br/>}
    </span>
	)
}

export function linkResolver(doc) {
  if (doc.type === 'case_study') return `/work/${doc.uid}`;
  return `/${doc.uid}`;
}

function propsWithUniqueKey(props, key) {
  return Object.assign(props || {}, { key });
}

export function htmlSerializer(type, element, content, children, key) {
    let props = {};
    switch(type) {
        case Elements.heading1:
          return React.createElement('h1', propsWithUniqueKey(props, key), children);
        case Elements.heading2:
          return React.createElement('h2', propsWithUniqueKey(props, key), children);
        case Elements.heading3:
          return React.createElement('h3', propsWithUniqueKey(props, key), children);
        case Elements.heading4:
          return React.createElement('h4', propsWithUniqueKey(props, key), children);
        case Elements.heading5:
          return React.createElement('h5', propsWithUniqueKey(props, key), children);
        case Elements.heading6:
          return React.createElement('h6', propsWithUniqueKey(props, key), children);
        case Elements.paragraph:
          return React.createElement('p', propsWithUniqueKey(props, key), children);
        case Elements.preformatted:
          return React.createElement('pre', propsWithUniqueKey(props, key), children);
        case Elements.strong:
          return React.createElement('strong', propsWithUniqueKey(props, key), children);
        case Elements.em:
          return React.createElement('em', propsWithUniqueKey(props, key), children);
        case Elements.listItem:
          return React.createElement('li', propsWithUniqueKey(props, key), children);
        case Elements.oListItem:
          return React.createElement('li', propsWithUniqueKey(props, key), children);
        case Elements.list:
          return React.createElement('ul', propsWithUniqueKey(props, key), children);
        case Elements.oList:
          return React.createElement('ol', propsWithUniqueKey(props, key), children);
        case Elements.image: 
          return <Image key={key} data={element} />;
        case Elements.label:
          props = element.data ? Object.assign({}, { className: element.data.label }) : {};
          return React.createElement('span', propsWithUniqueKey(props, key), children);
        case Elements.span:
          if (content) {
            return content.split("\n").reduce((acc, p) => {
              if (acc.length === 0) {
                return [p];
              } else {
                const brIndex = (acc.length + 1)/2 - 1;
                const br = React.createElement('br', propsWithUniqueKey({}, brIndex));
                return acc.concat([br, p]);
              }
            }, []);
          } else {
            return null;
          }
        case Elements.hyperlink:
          if (element.data.link_type === "Web") {
            const targetAttr = element.data.target ? { target: element.data.target } : {};
            const relAttr = element.data.target ? { rel: 'noopener' } : {};
            props = Object.assign({ 
              href: element.data.url || linkResolver(element.data)
            }, targetAttr, relAttr);
            return React.createElement('a', propsWithUniqueKey(props, key), children);
          }
          return <Link key={key} to={linkResolver(element.data)}>{children.join('')}</Link>
        default: 
          return null;
    }
};