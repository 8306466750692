import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ title, description, path, image }) => {

  const defaults = {
    title : `Kallan & Co`,
    description: `We work with visionary companies and people to create the brands, products and user experiences of the future`,
    baseUrl: ``,
    image: `kallanco_og_image.png`
  };

  if (defaults.baseUrl === `` && typeof window !== `undefined`) {
    defaults.baseUrl = window.location.origin;
  }

  if (defaults.baseUrl === '') {
    console.error('Please set a baseUrl in your site metadata!');
    return null;
  }

  if( path == null && typeof window !== `undefined` ){
    const re = new RegExp(defaults.baseUrl);
    path = window.location.toString().replace(re, ``);
  }

  const url = new URL(path || ``, defaults.baseUrl);
  const isDefaultTitle = !!title;
  title = title || defaults.title;
  description = description || defaults.description;
  image = new URL(image || defaults.image, defaults.baseUrl);

  return (
    <Helmet
      titleTemplate={isDefaultTitle ? `%s | ${defaults.title}` : ``}
    >
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Kallan &amp; Co" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
};

export default SEO;