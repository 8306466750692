import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ScrollLock from 'react-scrolllock';
import { handleLineBreaks } from '../utils/prismic';
import { CSSTransitionGroup } from 'react-transition-group';
import "../assets/style/MobileMenu.scss";

class MobileMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			locale: false,
			activeStudio: false
		}
	}

	componentDidUpdate(prevProps) {
    if (!this.state.locale && !!prevProps.state.data.locale) {
      this.setState({
        locale: this.props.state.data.locale,
        activeStudio: this.props.state.data.locale,
	      nordicDetails: this.props.state.data.studios.find(s => s.tag === "NORDIC"),
	      pacificDetails: this.props.state.data.studios.find(s => s.tag === "PACIFIC"),
	      melbourneDetails: this.props.state.data.studios.find(s => s.tag === "MELBOURNE")
      })
    }
  }

  changeDetails(target) {
  	this.setState({
  		activeStudio: target
  	})
  }

  render() {
		const { visible, toggleMobileMenu } = this.props;
		const { locale, activeStudio, nordicDetails, pacificDetails, melbourneDetails } = this.state;
		const navLinks = [
			'work',
			'about',
			'studios'
		]

        return (
			<ScrollLock
				isActive={visible}
			>
				<div
					className={`mobile_menu ${visible ? 'active' : ''}`}
				>
        <nav>
          <CSSTransitionGroup
          transitionName="slide-up"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
		        >
				      {navLinks.map((link, i) =>
				      	<NavLink
									key={i}
									className="mobile_menu_link"
									title={link}
									to={`/${link}`}
									onClick={() => toggleMobileMenu(false)}
									style={{"transitionDelay": `${ i * .05 }s` }}
								>
									{link.charAt(0).toUpperCase() + link.slice(1)}
								</NavLink>
				      )}
				    </CSSTransitionGroup>
					</nav>
					{!!locale &&
						<div className="menu_contact">
							<div>
							{locale === "NORDIC"
								? <p>
										<span
											className={activeStudio === "NORDIC" ? 'active' : 'muted'}
											onClick={() => this.changeDetails("NORDIC")}
										>
											Helsinki
										</span>
										<span className="muted separator">&nbsp;/&nbsp;</span>
										<span
											className={activeStudio === "PACIFIC" ? 'active' : 'muted'}
											onClick={() => this.changeDetails("PACIFIC")}
										>
											Christchurch
										</span>
										<span className="muted separator">&nbsp;/&nbsp;</span>
										<span
											className={activeStudio === "MELBOURNE" ? 'active' : 'muted'}
											onClick={() => this.changeDetails("MELBOURNE")}
										>
											Melbourne
										</span>
									</p>
								: <p>
										<span
											className={activeStudio === "PACIFIC" ? 'active' : 'muted'}
											onClick={() => this.changeDetails("PACIFIC")}
										>
											Christchurch
										</span>
										<span className="muted separator">&nbsp;/&nbsp;</span>
										<span
											className={activeStudio === "MELBOURNE" ? 'active' : 'muted'}
											onClick={() => this.changeDetails("MELBOURNE")}
										>
											Melbourne
										</span>
										<span className="muted separator">&nbsp;/&nbsp;</span>
										<span
											className={activeStudio === "NORDIC" ? 'active' : 'muted'}
											onClick={() => this.changeDetails("NORDIC")}
										>
											Helsinki
										</span>
									</p>
								}
								<p>
									{activeStudio === "NORDIC" && (
										<span>
											{handleLineBreaks(nordicDetails.address)}
											<br />
                                            <a href={`tel:${nordicDetails.phone_number.replace(/\s+/g, '')}`} ga-on="click" ga-event-category="contact" ga-event-action={`call-helsinki`}>{nordicDetails.phone_number}</a>
											<br />
											<a
												href={`mailto:hello@kallan.co`}
												ga-on="click"
												ga-event-category="contact"
												ga-event-action={`email-hello`}
											>
												hello@kallan.co
											</a>
										</span>
									)}
									{activeStudio === "PACIFIC" && (
										<span>
											{handleLineBreaks(pacificDetails.address)}
											<br />
                                            <a href={`tel:${pacificDetails.phone_number.replace(/\s+/g, '')}`} ga-on="click" ga-event-category="contact" ga-event-action={`call-christchurch`}>{pacificDetails.phone_number}</a>
											<br />
											<a
												href={`mailto:hello@kallan.co`}
												ga-on="click"
												ga-event-category="contact"
												ga-event-action={`email-hello`}
											>
												hello@kallan.co
											</a>
										</span>
									)}
									{activeStudio === "MELBOURNE" && (
										<span>
											{handleLineBreaks(melbourneDetails.address)}
											<br />
                                            <a href={`tel:${melbourneDetails.phone_number.replace(/\s+/g, '')}`} ga-on="click" ga-event-category="contact" ga-event-action={`call-melbourne`}>{melbourneDetails.phone_number}</a>
											<br />
											<a
												href={`mailto:hello@kallan.co`}
												ga-on="click"
												ga-event-category="contact"
												ga-event-action={`email-hello`}
											>
												hello@kallan.co
											</a>
										</span>
									)}
								</p>
							</div>
						</div>
					}
				</div>
			</ScrollLock>
		)
	}
}

const mapStateToProps = state => {
	return {
    state
  }
}

export default connect(mapStateToProps)(MobileMenu)