import { Prismic, apiEndpoint } from '../../utils/prismic';
import ls from 'local-storage';

export const RECEIVE_DATA = "RECEIVE_DATA";
export const RECEIVE_IPLOCALE = "RECEIVE_IPLOCALE";
export const RECEIVE_BUILD_HASH = "RECEIVE_BUILD_HASH";
export const RECEIVE_SET_LOADED = "RECEIVE_SET_LOADED";

// ALL DATA

export function fetchDataIfNeeded() {
	return async (dispatch, getState) => {
		const lsSiteData = ls('siteData');
		if (!!lsSiteData) {
			return dispatch(hydrateAndRecheck(dispatch, JSON.parse(lsSiteData), RECEIVE_DATA))
		} else {
			const state = getState()
			if (!state.data.loaded) {
				return dispatch(fetchData())
			}
		}
	}
}

/**
 * Busts the localStorage cache under the following conditions:
 * 1. There's no build hash in the cache
 * 2. The cached build hash is different to the current build hash
 *
 * @author Josh Smith <josh@batch.nz>
 * @return {Promise}
 */
export function bustCacheIfNeeded() {
	return async (dispatch, getState) => {
		let { buildHash } = getState();
		const lsbuildHash = ls('buildHash');

		if( buildHash == null ){
			buildHash = await fetchBuildHash();
		}

		// Clear localStorage if there's no build version cached or the build version has changed
		if( lsbuildHash == null || lsbuildHash !== buildHash ){
			ls.clear();
			ls('buildHash', buildHash);
		}

		return dispatch(receiveData(buildHash, RECEIVE_BUILD_HASH))
	}
}

/**
 * Returns the build hash from manifest.json
 * @author Josh Smith <josh@batch.nz>
 * @return {Promise}
 */
async function fetchBuildHash() {
	const response = await fetch(`/meta.json`);
	const {version} = await response.json();
	return version;
}

function fetchData() {
	return dispatch => {
		return Prismic.getApi(apiEndpoint).then(api => {
	    return api.query('', { pageSize : 100 })
		    .then(res => {
		    	ls('siteData', JSON.stringify(res.results))
		      return dispatch(receiveData(res.results, RECEIVE_DATA))
		    })
	  })
	}
}

function hydrateAndRecheck(dispatch, data, action) {
	return dispatch => {
		dispatch(fetchData())
		return dispatch(receiveData(data, action))
	}
}

function receiveData(data, action) {
	return {
		type: action,
		data
	}
}

// IP LOCALE

export function fetchIpIfNeeded() {
	return (dispatch, getState) => {
		const lsLocale = ls('locale');
		if (!!lsLocale) {
			return dispatch(receiveData(lsLocale, RECEIVE_IPLOCALE))
		} else {
			return dispatch(fetchIp())
		}
	}
}

function fetchIp() {
	return dispatch => {
		// Set the default location
		let locale = 'NORDIC';

		// Fire off a request to the IP address lookup API
		return fetch('https://ipapi.co/json/')
			.then(res => res.json())

			// Set locale based on the country
			.then(resJson => {
				switch(resJson.country) {
				  case 'NZ':
				  	locale = 'PACIFIC';
				  	break;
				  case 'AU':
				  	locale = 'MELBOURNE';
				  	break;
				  default:
				  	locale = 'NORDIC';
				  	break;
				}

				// Store the locale in localStorage
				ls('locale', locale);

				// Update the Redux store
				return dispatch(receiveData(locale, RECEIVE_IPLOCALE));
			})

			// Don't store erronous default locale in localStorage, just update the Redux store
			.catch(err => {
				return dispatch(receiveData(locale, RECEIVE_IPLOCALE))
			});
	}
}

/**
 * Sets whether all site data has loaded
 * @author Josh Smith <josh@batch.nz>
 */
export function setDataLoaded() {
	return dispatch => dispatch(receiveData(true, RECEIVE_SET_LOADED));
}