import React from 'react';
import FeaturedWorkItem from './FeaturedWorkItem';
import { Link } from 'react-router-dom';
import '../assets/style/FeaturedWork.scss';

function FeaturedWork(props) {
	const { cases, work } = props;
	
	return (
		<div className="featured desktop_inner">
			<div className="featured_work">
				{work && cases.length &&
					work.filter((entry) => entry.item.id).map((entry, index) =>
						<FeaturedWorkItem
							cases={cases}
							key={entry.item.id}
							showImage={true}
							id={entry.item.id}
							index={index}
						/>
					)
				}
			</div>
			<div className="featured_more inner">
				<Link className="button black" to="/work">Show me everything</Link>
			</div>
		</div>
	)
}

export default FeaturedWork