import React from 'react';
import CaseHeaderImage from './CaseHeaderImage';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer, linkResolver } from '../utils/prismic';

function CaseStudyHeader(props) {
	const { title, intro, bg, industryList, serviceList, website, website_url, image, workPageImage, headerImageBackgroundColor } = props;
	const winWidth = window.innerWidth;
	const headerImg = winWidth >= 700
		? image
		: !!workPageImage
		? workPageImage
		: image

	return (
		<div
			className="case_study_header"
			style={{backgroundColor: bg ? bg : null}}
		>
			<div className="case_study_image">
				{headerImg &&
					<CaseHeaderImage
						data={headerImg}
						padded={true}
						bg={headerImageBackgroundColor}
					/>
				}
			</div>
			<div className="case_study_intro">

				<div className="case_description">

					{intro &&
						<div>
							{RichText.render(intro, linkResolver, htmlSerializer)}
						</div>
					}

				</div>
				<div className="case_details">

					{title &&
						<>
						<h1>{title}</h1>
						</>
					}
					{industryList &&
						<>
						<h6>Industries</h6>
						<span>{industryList}</span>
						</>
					}
					{serviceList &&
						<>
						<h6>Outcomes</h6>
						<span>{serviceList}</span>
						</>
					}
					{website_url &&
						<>
						<h6>Website</h6>
						<span className="case_website">
							<a
								href={website_url}
								target="_blank" 
								rel="noopener noreferrer"
							>
							{website ? 
								website
							: 
							website_url}
							</a>
						</span>
						</>
					}

				</div>
			</div>
		</div>
	)
}

export default CaseStudyHeader