import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import Fader from './Fader';
import ParallaxCache from '../components/Parallax/ParallaxCache';
import Image from './Image';
//import SrcsetImage from '../components/SrcsetImage';
import { normalizeTitleField, handleLineBreaks } from '../utils/prismic';

function Studio(props) {
	const { data, index } = props;
	const { city, country, intro, address, key_contact, key_email, phone_number, image, illustration } = data;
	const introText = normalizeTitleField(intro)
	const winWidth = window.innerWidth;
    
	return (
        <Fader>
		<div className="studio">
        <ParallaxCache />
			<div className="studio_content_wrap">
				<div className="studio_content">
                    <h1>{city}<span className="country"> - {country}</span></h1>
                    <div className="studio_description"><p>{introText}</p></div>
                    <div className="studio_contacts">
                        <div className="studio_contact">
                            <h2>Contact</h2>
                            <p>{key_contact}<br />
                            {key_email &&
                                <a className="email" href={`mailto:${key_email}`} ga-on="click" ga-event-category="contact" ga-event-action={`email-${key_email.split('@')[0]}`}>{key_email}</a>
                            }
                            <br />
                            {winWidth < 700
                            ? <a className="phone" href={`tel:${phone_number.replace(/\s+/g, '')}`}>{phone_number}</a>
                            : phone_number
                            }
                            </p>
                        </div>
                        <div className="studio_address">
                            <h3>Address</h3>
                            <p>{handleLineBreaks(address)}</p>
                        </div>
                    </div>
				</div>
                <div className="studio_image">
                    <ParallaxBanner
                        className="your-class"
                        layers={[
                            {
                                image: `${image.url}`,
                                amount: 0.2,
                            },
                        ]}
                        style={{
                            height: '100%',
                        }}
                    >
                        {illustration &&
                            <Image
                                key={illustration.url}
                                data={illustration}
                                width={180}
                                pinnable={false}
                                lazy={index > 0}
                            />
                        }
                    </ParallaxBanner>

                </div>
            </div>
        </div>
        </Fader>
        
	)
}

export default Studio