import React from 'react';
import SrcsetImage from './SrcsetImage';
import '../assets/style/ImageGrid.scss';

function ImageGrid(props) {
	const { images, bg } = props;

	return (
		<div style={{backgroundColor: bg ? bg : 'white'}}>
			<div className="inner case_grid_block image_grid">
				{images.map((img, i) => (
					<SrcsetImage key={i} data={img.image} compress={img.compress_image !== false}/>
				))}
			</div>
		</div>
	)
}

export default ImageGrid