import { useEffect } from 'react';	
import qs from 'qs'
import Prismic from 'prismic-javascript'
import { apiEndpoint, linkResolver } from '../utils/prismic';

const client = Prismic.client(apiEndpoint)
 
const Preview = ({ history, location }) => {
  useEffect(() => {
    const params = qs.parse(location.search.slice(1))
    if (!params.token) {
      return console.warn(`No token available, check your configuration`)
    }
 
    client.previewSession(params.token, linkResolver, '/')
      .then(url => history.push(url))
  })
  return null
}
 
export default Preview