import React from 'react';
import * as prismicH from "@prismicio/helpers"

class SrcsetImage extends React.Component {
  render() {
    const { title = "Kallan & Co", data, bleed = false, caption, alt, lazy = true, compress = true } = this.props;
    const { url, dimensions } = data;
    const one_x = data.one_x ? data.one_x.url : url;
    const two_x = data.two_x ? data.two_x.url : url;
    const bleed_one_x = data.bleed_one_x ? data.bleed_one_x.url : url;
    const bleed_two_x = data.bleed_two_x ? data.bleed_two_x.url : url;
    const mob = data.mob ? data.mob.url : url;
    const winWidth = window.innerWidth;

    let src = url;
    let srcSet = `${one_x}&q=90 1x, ${two_x}&q=72 2x`
    let bleedSrc = url;
    let bleedSrcSet = `${bleed_one_x}&q=90 1x, ${bleed_two_x}&q=72 2x`
    let mobSrc = mob;

    const imageSrcWithNoCompression = prismicH.asImageSrc(data, {
        auto: undefined,
        w: 1.0,
        h: 1.0,
      })

    if (!compress) {
      srcSet = null;
      bleedSrcSet = null;
      src = imageSrcWithNoCompression;
      bleedSrc = imageSrcWithNoCompression;
    }

    return (
      <figure>
        {winWidth < 560
          ? <img
              alt={title}
              loading={lazy ? 'lazy' : 'eager'}
              src={mobSrc}
              width={dimensions.width}
              height={dimensions.height}
              style={{
                width: dimensions.width,
                height: dimensions.height,
              }}
            />
          : !!bleed
          ? <img
              alt={title}
              loading={lazy ? 'lazy' : 'eager'}
              src={bleedSrc}
              srcSet={bleedSrcSet}
              width={dimensions.width}
              height={dimensions.height}
              style={{
                width: dimensions.width,
                height: dimensions.height,
              }}
            />
          : <img
              alt={title}
              loading={lazy ? 'lazy' : 'eager'}
              src={src}
              srcSet={srcSet}
              width={dimensions.width}
              height={dimensions.height}
              style={{
                width: dimensions.width,
                height: dimensions.height,
              }}
            />
        }
        {caption && (alt || data.alt) && (
          <figcaption className="muted case_image_caption">
            {alt ? alt : data.alt}
          </figcaption>
        )}
      </figure>
    )

  }
}

export default SrcsetImage;