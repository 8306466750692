import React from 'react';
import SrcsetImage from './SrcsetImage';
import TextBlock from './TextBlock';
import SingleVideo from './SingleVideo';

function TextAndImage(props) {
	const { image, video, alignment, bg, textAlign, text, compressImage } = props;
	let valign;
	if (textAlign === 'center' || textAlign === 'centre' || textAlign === 'overlap') {
		valign = 'center'
	} else {
		valign = 'flex-start'
	}
	
	return (
		<div
			className="case_imgtxt_block inner"
			data-alignment={textAlign}
			data-side={alignment || 'left'}
			style={{
				display: 'flex',
				flexWrap: 'nowrap',
				alignItems: valign
			}}
		>
			<div
				data-content="image"
				data-order={alignment === 'left' ? 0 : 1}
				style={{order: alignment === 'left' ? 0 : 1}}
			>
				{video.url
					? <SingleVideo
							url={video.url}
						/>
					: <SrcsetImage
							data={image}
							full={true}
							width={textAlign === 'overlap' ? 1035 : false}
							compress={compressImage}
						/>
				}
			</div>
			<div
				data-content="text"
				data-order={alignment === 'left' ? 1 : 0}
				data-mobile-top-padd={bg === '#ffffff' || !bg ? 'no' : 'yes'}
				style={{order: alignment === 'left' ? 1 : 0, backgroundColor: bg}}
			>
				<TextBlock
					bg={bg}
					text={text}
					alignment="left"
				/>
			</div>
		</div>
	)
}

export default TextAndImage;	