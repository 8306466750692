import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import { LastLocationProvider } from 'react-router-last-location';
import Home from '../views/Home';
import Work from '../views/Work';
import About from '../views/About';
import Studios from '../views/Studios';
import UX from '../views/Ux';
import UXDE from '../views/Ux-de';
import CaseStudy from '../views/CaseStudy';
import Four0Four from '../views/Four0Four';
import Preview from '../views/Preview';
 
class UrlRouter extends React.Component {
	render() {
		const { data } = this.props;

		return (
			<ParallaxProvider>
				<Router>
					<LastLocationProvider>
						<Switch>
							<Route exact path="/work" render={() =>
								<Work data={data} />
							} />
							<Route exact path="/about" render={() =>
								<About data={data} />
							} />
							<Route exact path="/studios" render={() =>
								<Studios data={data} />
							} />
							<Route exact path="/work/:slug" render={() =>
								<CaseStudy data={data} />
							} />
							<Route exact path="/" render={() =>
								<Home data={data} />
							} />
							<Route exact path="/ux" render={() =>
								<UX data={data} />
							} />
							<Route exact path="/ux/de" render={() =>
								<UXDE data={data} />
							} />
							<Route exact path='/preview' component={Preview} />
							<Route render={() =>
								<Four0Four data={data} />
							} />
						</Switch>
					</LastLocationProvider>
				</Router>
			</ParallaxProvider>
		)
	}
}

export default connect()(UrlRouter);