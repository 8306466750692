import React from 'react';

// Mostly a copy of the SrcsetImage comp. Should be refactored eventually

class CaseHeaderImage extends React.Component {
  constructor(props) {
    super(props);
      this.timer = null;
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.timer = setTimeout(function () {
        this.figRef.current.classList.add('loading')
    }.bind(this), 3000);
  }
    
  setImageLoaded() {
    clearTimeout(this.timer);
    this.figRef.current.classList.remove('loading')
    this.setState({
      loaded: true
    })
  }

  render() {
    const { title = "Kallan & Co", data, bg, padded } = this.props;
    const { url, dimensions } = data;
    const mob = data.mob ? data.mob.url : url;
    const winWidth = window.innerWidth;
    const { loaded } = this.state;
    const originalUrl = new URL(url);
    const parsedUrl = `${originalUrl.origin}${originalUrl.pathname}`
    this.figRef = React.createRef()
    
    return (
      <figure ref={this.figRef}
        style={padded ? { height: 0, overflow: 'hidden', backgroundColor: bg || '#f0f0f0', paddingBottom: ((dimensions.height / dimensions.width) * 100) + '%' } : {}}
      >
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        {winWidth < 560
          ? <img
              alt={title}
              loading="lazy"
              src={mob}
              onLoad={() => this.setImageLoaded()}
              style={{
                width: dimensions.width,
                height: dimensions.height,
                opacity: !!loaded ? 1 : 0,
                transition: 'opacity 0.5s 0.33s ease'
              }}
            />
          : <img
              alt={title}
              loading="lazy"
              src={parsedUrl}
              onLoad={() => this.setImageLoaded()}
              style={{
                width: dimensions.width,
                height: dimensions.height,
                opacity: !!loaded ? 1 : 0,
                transition: 'opacity 0.33s 0.33s ease'
              }}
            />
        } 
      </figure>
    )
  }
}

export default CaseHeaderImage;