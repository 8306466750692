import React from 'react';
import { connect } from 'react-redux';
import { MultilineReveal } from "react-text-reveal";
import { InView } from 'react-intersection-observer';
import { BrowserView, MobileView } from "react-device-detect";
import Fade from 'react-reveal/Fade';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Studio from '../components/Studio';
import Team from '../components/Team';
import { getStudiosOrderedByLocale } from '../redux/selectors';
import '../assets/style/Studios.scss';

class Studios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        heroInView: false
    }
  }

  render() {
    const { studioPage } = this.props.data;
    const { orderedStudios } = this.props;
    const { intro } = studioPage;

    return (
      <div className="studios">
        <SEO
          title="Studios"
          description={intro}
        />
        <Header />

        <BrowserView>
            <InView className="hero inner" as="div" triggerOnce onChange={
                (inView, entry) => {
                  if (inView) this.setState({heroInView: true})
                }
              }>
              <h1 className="bravo desktop">
                <MultilineReveal
                    animateOpacity={true}
                    canPlay={this.state.heroInView}
                    copy={["We are one team, spread over multiple","time-zones. Our broad reach gives us","wider cultural perspectives and a","unique approach to every project."]}
                    direction={"bottom"}
                    duration={700} // ms
                    ease={"cubic-bezier(0.12,0.66,0.29,0.96)"}
                    offset={"100px"}
                    multilineMasking={true}
                    multilineOffsetDelay={75} // ms
                />
              </h1>
            </InView>
        </BrowserView>
        <MobileView>
            <Fade>
            <div className="hero inner">
                <h1>{intro}</h1>
            </div>
            </Fade>
        </MobileView>

        <div className="studios_list inner">
            {orderedStudios.map((studio, i) =>
              <Studio
                key={i}
                data={studio}
                index={i}
              />
            )}
        </div>

        <div className="team inner">
            <div className="team_wrap">
              {orderedStudios.map((teamMember, i) =>
                  <Team key={i} data={teamMember} />
              )}
            </div>
        </div>

        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    orderedStudios: getStudiosOrderedByLocale(state)
  }
}

export default connect(mapStateToProps)(Studios);
