import React from 'react';

export default ({location, name, phone, email}) => {
  const winWidth = window.innerWidth;
  return (
    <div className="footer_contact">
        {location &&
            <h3>{location}</h3>
        }
        <p>
            {name}
            <br/>
            {winWidth < 700
                ? <a href={`tel:${phone.replace(/\s+/g, '')}`}>{phone}</a>
                : phone
            }
            <br/>
            {email &&
                <a
                    href={`mailto:${email}`}
                    ga-on="click"
                    ga-event-category="contact"
                    ga-event-action={`email-${email.split('@')[0]}`}
                >
                    {email}
                </a>
            }
        </p>
    </div>
  );
}