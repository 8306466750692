import { combineReducers } from 'redux';
import {
	RECEIVE_DATA,
	RECEIVE_IPLOCALE,
	RECEIVE_BUILD_HASH,
	RECEIVE_SET_LOADED
} from '../actions'

function data(state = {loaded: false}, action) {
	switch(action.type) {
		case RECEIVE_DATA:
			let siteData = {}
			if(Array.isArray(action.data)) {
				siteData = {
					contact: action.data.filter(i => i.type === 'contact').map(c => c.data)[0] || {},
					workPage: action.data.filter(i => i.type === 'cases').map(c => c.data)[0] || {},
					work: (() => {
                        const cases = action.data.filter(i => i.type === 'cases').map(c => c.data)[0]
                        if( cases == null || cases.ordered_case_list == null ) return {}

                        const caseStudies = cases.ordered_case_list.filter(i => i.case_study.type === 'case_study')
                        return caseStudies || {}
                    })(),
					nzWork: (() => {
                        const cases = action.data.filter(i => i.type === 'cases').map(c => c.data)[0]
                        if( cases == null || cases.nz_ordered_case_list == null ) return {}

                        const caseStudies = cases.nz_ordered_case_list.filter(i => i.case_study.type === 'case_study')
                        return caseStudies || {}
                    })(),
					homePage: action.data.filter(i => i.type === 'home_page').map(c => c.data)[0] || {},
					aboutPage: action.data.filter(i => i.type === 'about').map(c => c.data)[0] || {},
                    studioPage: action.data.filter(i => i.type === 'studios').map(c => c.data)[0] || {},
					uxPage: action.data.filter(i => i.type === 'ux').map(c => c.data)[0] || {},
					cases: action.data
						.filter(i => i.type === 'case_study')
						.map(s => ({
							uid: s.uid,
							id: s.id,
							slug: s.slugs[0],
							coming_soon: s.coming_soon,
							...s.data
						})),
					studios: action.data.filter(i => i.type === 'studio').map(s => ({
						id: s.id,
						slug: s.slugs[0],
						...s.data
					})),
					article: action.data.filter(i => i.type === 'article').map(c => c.data)[0] || {},
					footer: action.data.filter(i => i.type === 'footer').map(c => c.data)[0] || {},
				}
			}
			return {
				...state,
				...siteData
			}
		case RECEIVE_BUILD_HASH:
			return {
				...state,
				buildHash: action.data
			}
		case RECEIVE_IPLOCALE:
			return {
				...state,
				locale: action.data
			}
		case RECEIVE_SET_LOADED:
			return {
				...state,
				loaded: action.data
			}
		default:
			return state
	}
}

export default combineReducers({
	data
});