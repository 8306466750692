import React from 'react';
import { InView } from 'react-intersection-observer'
import SEO from '../components/SEO';
import Fade from 'react-reveal/Fade';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FeaturedWork from '../components/FeaturedWork';
import '../assets/style/Home.scss';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        heroInView: false
    }
  }

  render() {
    const { homePage, cases } = this.props.data
    const { intro, featured_work } = homePage
    const featWork = featured_work

    return (
      <div className="home">
        <SEO
          description={intro}
        />
        <Header />
        <InView className="hero" as="div" triggerOnce onChange={
            (inView, entry) => {
              if (inView) this.setState({heroInView: true})
            }
          }>
          <Fade bottom duration={500} distance="60px" delay={100}><h1>A strategic design studio</h1></Fade>
          <Fade bottom duration={500} distance="60px" delay={150}><h2>Unlocking new futures</h2></Fade>
          <Fade bottom duration={500} distance="60px" delay={200}><p className='intro'>We utilise the latest design methods, exceptional talent and the most sophisticated technology to release strategic potential</p></Fade>
        </InView>

        <FeaturedWork
            cases={cases}
            work={featWork}
        />

        <Footer />
      </div>
    )
  }
}

export default Home;
