import React from 'react';
import Fade from 'react-reveal/Fade';

function Fader(props) {
	const winWidth = window.innerWidth;

	return (
		winWidth > 560
			? <Fade bottom duration={700} distance="40px">
					{props.children}
				</Fade>
			: props.children
	)
}

export default Fader;