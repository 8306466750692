import React from 'react';
import { Link } from 'react-router-dom';
import Fader from './Fader';
import Image from './Image';

function WorkItem(props) {
	const { cases, id, showImage, index } = props;
	const singleCase = cases && cases.find(caseItem => caseItem.id === id);
	const winWidth = window.innerWidth;

	return (
		<Fader>
			<div className={`featured_work_item ${singleCase.coming_soon === 'true' ? 'soon' : ''}`}>
				{!!singleCase
					? <Link
							ga-on="click"
							ga-event-category="case"
							ga-event-action="view"
							to={singleCase.coming_soon === 'true' ? '#1' : `/work/${singleCase.uid}`}
						>
							{singleCase.work_page_cover.url && showImage &&
								<>
									<div className="featured_image"  style={winWidth >= 700 ? {paddingBottom: `${Math.floor((singleCase.work_page_cover.dimensions.height / singleCase.work_page_cover.dimensions.width) * 100)}%`} : {}}>
										<Image
											title={singleCase.title}
											data={singleCase.work_page_cover}
											lazy={index > 1}
										/>
									</div>
									<div className="featured_item_meta">
										<h4>{singleCase.title} {singleCase.coming_soon === 'true' && <span className="soon red">Coming soon</span>}</h4>
										<p>{singleCase.byline}</p>
									</div>
								</>
							}
						</Link>
					: null
				}
			</div>
		</Fader>
	)
}

export default WorkItem