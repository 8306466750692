import React from 'react';

class Image extends React.Component {
  render() {
    const { caption, width, height, pinnable = true, padded = false, lazy = true } = this.props;
    const { dimensions, url, alt, bleed, mob } = this.props.data;
    let imgUrl;
    if (mob && window.innerWidth <= 560) {
      imgUrl = mob.url;
    } else if (width === 'edges') {
      imgUrl = bleed.url
    } else {
      imgUrl = url
    }
    return (
      imgUrl && (
        <figure
          style={padded ? { height: 0, overflow: 'hidden', paddingBottom: ((dimensions.height / dimensions.width) * 100) + '%' } : {}}
        >
         <img
          alt={alt || 'Kallan & Co'}
          src={imgUrl}
          loading={lazy ? 'lazy' : 'eager'}
          data-pin-nopin={pinnable ? null : true}
          style={{
            width: width ? width : dimensions ? dimensions.width : '100%',
            height: height ? height : dimensions ? dimensions.height : 'auto',
          }}
          
         />
         {caption && <figcaption className="muted case_image_caption">{alt}</figcaption>}
        </figure>
      )
    )
  }
}

export default Image;