import React from "react";

function MenuIcon(props) {
  const { toggleMenu, active, fill } = props;
  const fillHex = fill === 'black' ? '#000' : '#fff';

  return (
    <button
      onClick={toggleMenu}
      className="nav_toggle"
    >
      {active
        ? <svg style={{maxWidth: '23px'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 20">
            <g clipPath="url(#clip0)">
              <path
                fill="#000"
                d="M1.414 0.808H25.414V2.808H1.414z"
                transform="rotate(45 1.414 .808)"
              />
              <path
                fill="#000"
                d="M0 0H24V2H0z"
                transform="scale(-1 1) rotate(45 -9.46 -20.082)"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path
                  fill="#ffffff"
                  d="M0 0H18.385V18.385H0z"
                  transform="translate(0 .808)"
                />
              </clipPath>
            </defs>
          </svg>
        : <svg style={{maxWidth: '23px'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 20">
            <g fill={fillHex} clipPath="url(#clip0)">
              <path d="M-2 5H22V7H-2z" />
              <path d="M-2 13H22V15H-2z" />
            </g>
            <defs>
              <clipPath id="clip0">
                <path
                  fill={fillHex}
                  d="M0 0H18.385V18.385H0z"
                  transform="translate(0 .808)"
                />
              </clipPath>
            </defs>
          </svg>
      }
    </button>
  )
}

export default MenuIcon;