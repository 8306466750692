import React from 'react';
import SrcsetImage from './SrcsetImage';
import SingleVideo from './SingleVideo';
import TextAndImage from './TextAndImage';
import TextBlock from './TextBlock';
import CaseImage from './CaseImage';
import ImageGrid from './ImageGrid';
import VideoSlice from './VideoSlice';

function Slicer(props) {
	const { type, content } = props;
	const { items } = content; // image grid
	const { 
		image,
		text_1,
		text_2, 
		image_1, 
		image_2, 
		text, 
		video, 
		video_1,
		video_2,
		overlap_background_colour, 
		text_alignment, 
		alignment,
		left_image,
		left_order,
		left_text,
		right_image,
		right_order,
		right_text,
		background_colour,
		image_width,
		column_alignment,
		size_ratio,
		width,
		max_width,
		video_slice_url,
		video_slice_autoplay,
		video_slice_controls,
		video_slice_loop,
		video_slice_play_button,
		video_slice_size,
		video_slice_poster_image,
		compress_image,
		compress_image_1,
		compress_image_2,
		compress_right_image,
		compress_left_image,
	} = content.primary;

	switch(type) {
		case "video":
			return (
				<VideoSlice
					url={video_slice_url}
					autoplay={video_slice_autoplay}
					controls={video_slice_controls}
					loop={video_slice_loop}
					playButton={video_slice_play_button}
					size={video_slice_size}
					poster={video_slice_poster_image}
				/>
			)
		case "image_grid":
			return (
				<ImageGrid
					images={items}
					bg={background_colour}
				/>
			)
		case "full_width":
			return (
				video.url
					? <SingleVideo
							width={image_width}	
							url={video.url}
						/>
					: image.url
					?
						<CaseImage
							width={image_width}
							data={image}
							caption={true}
							compressImage={compress_image !== false}
						/>
					: null
			)
		case "image___text":
			return (
				<TextAndImage
					alignment={alignment}
					image={image}
					video={video}
					bg={overlap_background_colour}
					textAlign={text_alignment}
					text={text}
					caption={true}
					compressImage={compress_image !== false}
				/>
			)
		case "2_images":
			return (
				<div className={`two_images ${size_ratio === '35-65' ? 'left-smaller' : size_ratio === '65-35' ? 'right-smaller' : 'even'} ${width === 'container' ? 'inner' : width === 'fullbleed' ? 'fullbleed' : 'bleed'}`} data-alignment={alignment}>

					<div className="left">
						{video_1.url 
							?	<SingleVideo
									url={video_1.url}
									inner={false}
								/>
							: image_1.url
							? <SrcsetImage
									caption={true}
									data={image_1}
									compress={compress_image_1 !== false}
								/>
							: null
						}
					</div>
					<div className='right'>
						{video_2.url 
							? <SingleVideo
									url={video_2.url}
									inner={false}
								/>
							: image_2.url
							? <SrcsetImage
									caption={true}
									data={image_2}
									compress={compress_image_2 !== false}
								/>
							: null
						}
					</div>
				</div>
			)
		case "2_text_blocks":
			return (
				<div>
					{text_1 &&
						<TextBlock
							text={text_1}
						/>
					}
					{text_2 &&
						<TextBlock
							text={text_2}
						/>
					}
				</div>
			)
		case "text_block":
			return (
				<div className="inner">
					{text 
						? <TextBlock
								alignment={alignment}
								text={text}
								maxWidth={max_width}
							/>
						: null
					}
				</div>
			)
		case "2_columns_content":
			const bg = background_colour || "white"
			return (
				<div
					className={`case_custom_block ${bg !== "white" && bg !== '#ffffff' && 'padd'}`}
					style={{backgroundColor: bg}}
				>
					<div className="inner" data-alignment={column_alignment || 'flex-start'} style={{display: 'flex', justifyContent: 'space-between'}}>
						<div className="case_custom_column" style={{display: 'flex', flexDirection: 'column'}}>
							{(!!video_1.url || !!left_image.url) &&
								<div data-order={left_order === 'image first' ? 0 : 1} style={{order: left_order === 'image first' ? 0 : 1}}>
									{video_1.url
										? <SingleVideo
												url={video_1.url}
											/>
										: left_image.url
										? <SrcsetImage
												data={left_image}
												compress={compress_left_image !== false}
											/>
										: null
									}
								</div>
							}
							{!!left_text.length &&
								<div data-order={left_order === 'image first' ? 1 : 0} style={{order: left_order === 'image first' ? 1 : 0}}>
									<TextBlock
										text={left_text}
										bg={bg}
									/>
								</div>
							}
						</div>
						<div className="case_custom_column" style={{display: 'flex', flexDirection: 'column'}}>
							{(!!video_2.url || !!right_image.url) &&
								<div style={{order: right_order === 'image first' ? 0 : 1}}>
									{video_2.url
										? <SingleVideo
												url={video_2.url}
											/>
										: right_image.url
										? <SrcsetImage
												data={right_image}
												compress={compress_right_image !== false}
											/>
										: null
									}
								</div>
							}
							{!!right_text.length &&
								<div style={{order: right_order === 'image first' ? 1 : 0}}>
									<TextBlock
										text={right_text}
										bg={bg}
									/>
								</div>
							}
						</div>
					</div>
				</div>
			)
		default:
			return false
	}
}

export default Slicer