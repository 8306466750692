import React from 'react';
import WorkItem from './WorkItem';

function WorkList(props) {
	const { cases, work, filter, workListRef } = props;
	
	return (
		<div className="work_list desktop_inner" ref={workListRef}>
			{work.length &&
				work.map((entry, index) =>
					filter === 'all' || !!entry.case_study.tags.some(el => el === filter)
						? <WorkItem
								cases={cases}
								key={entry.case_study.id}
								showImage={true}
								id={entry.case_study.id}
								index={index}
							/>
						: null
				)
			}
		</div>
	)
}

export default WorkList;
