import React, { useState, useRef } from 'react';
import '../assets/style/VideoSlice.scss';

function VideoSlice(props) {
  const { url, autoplay, controls, loop, playButton, size, poster } = props;

  const [playVideo, setPlayVideo] = useState(autoplay);
  const videoContainer = useRef(null);

  const handlePlayVideo = () => {
    if (playVideo) return
    setPlayVideo(true);
    videoContainer.current.firstElementChild.play();
    videoContainer.current.firstElementChild.controls = true;
  }  
  
  return (
    <div className={size === 'container' ? 'inner' : ''}>
      <div className="VideoSlice">
        <div
          ref={videoContainer}
          className="VideoSlice__inner"
          dangerouslySetInnerHTML={{
            __html: `
              <video
                src="${url}"
                poster="${poster.url ? poster.url : ''}"
                class="VideoSlice__video"
                ${controls ? 'controls' : ''}
                ${autoplay ? 'autoplay' : ''}
                ${loop ? 'loop' : ''}
                playsInline
              />
            `,
          }}
        />
        {!playVideo && (
          <div className="VideoSlice__cover">
            <button onClick={() => handlePlayVideo()}>
              {playButton && (
                <div className="VideoSlice__icon"></div>
              )}  
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoSlice; 