import React from 'react';
import Fader from './Fader';
import SrcsetImage from './SrcsetImage';
import CountryCodeNZ from './CountryCode/CountryCodeNZ'
import CountryCodeAU from './CountryCode/CountryCodeAU'
import CountryCodeFIN from './CountryCode/CountryCodeFIN'

function TeamMember(props) {
	const { image1, email, name, phone, title } = props.data;
	const { country } = props
	return (
		<Fader>
			<div className="team_card">
				{image1.url &&
					<div className="team_card_image">
						<SrcsetImage
							title={name}
							data={image1}
						/>
					</div>
				}
				<div className="team_card_content">
					{name &&
						<h4>{name}</h4>
					}
                    <span className="title">
					{title &&
						<p>
                            {title}
                            {country && country === 'New Zealand' && (
                                <CountryCodeNZ color="#417F63"/>
                            )}
                            {country && country === 'Australia' && (
                                <CountryCodeAU color="#D84200"/>
                            )}
                            {country && country === 'Finland' && (
                                <CountryCodeFIN color="#032AC7"/>
                            )}
                        </p>
					}
                    </span>
					{email && 
						<p>
							<a
								href={`mailto:${email}`}
								ga-on="click"
								ga-event-category="contact"
								ga-event-action={`email-${email.split('@')[0]}`}
							>
								{email}
							</a>
						</p>
					}
					{phone &&
						<p>{phone}</p>
					}
				</div>
			</div>
		</Fader>
	)
}

export default TeamMember;