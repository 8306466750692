import React, { useState } from 'react';
import TopBar from './TopBar';
import MobileMenu from './MobileMenu';
import '../assets/style/Header.scss';

function Header(props) {
	const { bg, caseStudy } = props;
	const [showMenu, toggleMobileMenu] = useState(false);
		
	return (
		<div className="header" data-background={bg}>
			<MobileMenu
				toggleMobileMenu={toggleMobileMenu}
				visible={showMenu}
			/>
			<TopBar
				caseStudy={caseStudy || false}
				bg={bg}
				toggleMenu={() => toggleMobileMenu(!showMenu)}
				active={showMenu}
			/>

		</div>
	)
}

export default Header;