import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/style/NextProject.scss';

function NextProject(props) {
	const { caseId, work, cases } = props;
	let nextCaseId;
	let nextCase;
	const currIndex = work.findIndex(w => w.case_study.id === caseId)
	if ( currIndex > -1 && currIndex < work.length-1 ) {
		nextCaseId = work[currIndex+1].case_study.id
	} else {
		nextCaseId = work[1].case_study.id
	}
	nextCase = cases.find(c => c.id === nextCaseId);
	const { title, uid } = nextCase;

	return (
		nextCase.coming_soon === "true"
		? null
		: <div className="next_project">
				<div className="inner">
					<NavLink
						to={`/work/${uid}`}
						ga-on="click"
						ga-event-category="case" 
						ga-event-action="view-nextprev"
					>
						<p className="faded">Next Project</p>
						<h3>{title}</h3>
					</NavLink>
				</div>
			</div>
	)
}

export default NextProject;