import React from 'react';
import { connect } from 'react-redux';
import { bustCacheIfNeeded, fetchDataIfNeeded, fetchIpIfNeeded, setDataLoaded } from './redux/actions';
import UrlRouter from './components/UrlRouter';
import Loading from './components/Loading';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      showLoading: false
    }
  }

  componentDidMount() {
    this.timeoutId = setTimeout(function () {
        document.body.classList.add('ready');
    }, 30);

    const { dispatch } = this.props;

    dispatch(bustCacheIfNeeded()).then(() => {
      Promise.all([
          dispatch(fetchDataIfNeeded()),
          dispatch(fetchIpIfNeeded())
        ]).then(() => dispatch(setDataLoaded()));
    });

    setTimeout(() => {
      if (!this.props.data.loaded) {
        this.setState({ showLoading: true })
      }
    }, 500);
  }

  render() {
    const { data } = this.props;
    const { showLoading } = this.state;
    return (
      data.loaded
        ? <UrlRouter data={data} />
        : showLoading
        ? <Loading />
        : null
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}

export default connect(mapStateToProps)(App);