import React from 'react';
import { Link } from 'react-router-dom';
import Fader from './Fader';
import SrcsetImage from './SrcsetImage';

function FeaturedWorkItem(props) {
	const { cases, id, showImage, index } = props;
	const featCase = cases.find(caseItem => caseItem.id === id) || false
	const { uid, title, work_page_cover, byline, coming_soon } = featCase;
	const winWidth = window.innerWidth;
	
  return (
  	!!featCase &&
  		<Fader>
				<div className="featured_work_item">
					<Link
						ga-on="click"
						ga-event-category="case"
						ga-event-action="view"
						to={coming_soon === 'true' ? '#1' : `work/${uid}`}
						className={coming_soon === 'true' ? 'soon' : ''}
					>
						{showImage &&
							<div className="featured_image" style={winWidth >= 700 ? {paddingBottom: `${Math.floor((work_page_cover.dimensions.height / work_page_cover.dimensions.width) * 100)}%`} : {}}>
								<SrcsetImage
									title={title}
									data={work_page_cover}
									lazy={index > 1}
								/>								
							</div>
						}
						<div className="featured_item_meta">
							<h4>{title} {coming_soon === 'true' && <span className="soon red">Coming soon</span>}</h4>
							<p>{byline}</p>
						</div>
					</Link>
				</div>
			</Fader>
	)
}

export default FeaturedWorkItem;