import React from 'react';
import TeamCard from './TeamCard';

function Team(props) {
	const { data } = props;
	const { team, management, country } = data;
	
    return (
        <>
            {management.map((teamMember, i) =>
                <TeamCard key={i} data={teamMember} country={country} />
            )}
            {team.map((teamMember, i) =>
                <TeamCard key={i} data={teamMember} country={country} />
            )}
        </>
	)
}

export default Team