import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import Logo from './Logo';
import MenuIcon from './MenuIcon';
import '../assets/style/Nav.scss';
import LogoAnimation from '../assets/images/kallan-logo-animated.gif';

function Nav(props) {
	const { bg, toggleMenu, active, caseStudy } = props;
	const winWidth = window.innerWidth;
	const fill = winWidth < 700 && caseStudy
		? "black"
		: bg === "black"
		? "white"
		: "black"
	
	const activeNavStyle = {
		color: active ? '#fff' : fill
	}
	const defaultNavStyle = {
		color: active ? '#fff' : fill
	}
	const navItems = [
		'work',
		'about',
		'studios'
	]

	return (
		<div className="nav">
			<NavLink id="kallan-logo" title="Home" to="/">
                {caseStudy 
                    ? <Logo fill={active ? '#000' : fill} />
                    : <div className="logo-animation"><img alt="loading" width="600" src={LogoAnimation} /></div>
                } 
			</NavLink>
			<MenuIcon
				active={active}
				toggleMenu={toggleMenu}
				fill={active ? '#fff' : fill}
			/>
			<nav>
				{navItems.map((item, idx) =>
					<NavLink
						key={idx}
						title={item}
						style={defaultNavStyle}
						activeStyle={activeNavStyle}
						to={`/${item}`}
					>
						{item.charAt(0).toUpperCase() + item.slice(1)}
					</NavLink>	
				)}
			</nav>
		</div>
	)
}

export default withRouter(Nav)