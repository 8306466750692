import React from 'react';
import Nav from './Nav';
import '../assets/style/TopBar.scss';

function TopBar(props) {
	const { bg, toggleMenu, active, caseStudy } = props;

	return (
		<div className="topbar inner">
			<Nav
				caseStudy={caseStudy}
				bg={bg}
				toggleMenu={toggleMenu}
				active={active}
			/>
		</div>
	)
}

export default TopBar;