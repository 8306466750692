import React from 'react';
import { withRouter } from 'react-router-dom';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CaseStudyContent from '../components/CaseStudyContent';
import NextProject from '../components/NextProject';
import ScrollToTop from '../components/ScrollToTop';

class CaseStudy extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
            slug: false, 
            locale: false
		}
	}

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.slug !== prevState.slug) {
      return {
        slug: nextProps.match.params.slug,
        locale: prevState.locale
      }
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.locale && this.props.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }
  }

  render() {
    const { locale, slug } = this.state;
    const { work, nzWork, cases } = this.props.data;
    const nextProjectWork = !locale ? work : locale === 'NORDIC' ? work : nzWork
    const content = cases.find(caseItem => caseItem.uid === slug)
    if (content === undefined) {
      this.props.history.push('/work')
      return null;
    }

    return (
      <div className="case">
        <ScrollToTop />
        {!content
          ? <SEO
              title="Work"
              description="We work with visionary companies and people to create the brands, products and user experiences of the future"
            />
          : <SEO
              title={content.title}
              description={content.byline}
              image={content.case_study_header_image.url}
            />
        }
        {!!content &&
          <>
            <Header
              intro={false}
              bg={content.header_background_colour}
              caseStudy={true}
            />
            <CaseStudyContent
              key={content.id}
              content={content}
            />
            <NextProject
              caseId={content.id}
              cases={cases}
              work={nextProjectWork}
            />
            <div id="bottom">
                <Footer />
            </div>
          </>
        }
      </div>
    )
  }
}

export default withRouter(CaseStudy);

