import React from 'react';

const CountryCodeFIN = ({color}) => {
  return (
    <svg viewBox="0 0 212 137" style={{fill: color}}>
      <path fillRule="evenodd" clipRule="evenodd" d="M176.607 111.048C194.533 99.4385 204.432 84.2069 204.432 68.4024C204.432 52.5978 194.533 37.3663 176.607 25.7572C158.749 14.1921 133.671 6.81611 105.621 6.81611C77.5717 6.81611 52.4933 14.1921 34.6354 25.7572C16.7096 37.3663 6.81021 52.5978 6.81021 68.4024C6.81021 84.2069 16.7096 99.4385 34.6354 111.048C52.4933 122.613 77.5717 129.989 105.621 129.989C133.671 129.989 158.749 122.613 176.607 111.048ZM105.621 136.805C163.954 136.805 211.243 106.18 211.243 68.4024C211.243 30.6248 163.954 0 105.621 0C47.2883 0 0 30.6248 0 68.4024C0 106.18 47.2883 136.805 105.621 136.805Z"/>
      <path d="M62.9824 50.3015V86.6255H68.6855V71.8884H86.0023V66.3879H68.6855V55.802H88.128V50.3015H62.9824Z"/>
      <path d="M98.7606 50.3015V86.6255H104.464V50.3015H98.7606Z"/>
      <path d="M116.615 50.3015V86.6255H122.318L122.422 58.1371L141.502 86.6255H148.242V50.3015H142.539V78.0116L123.926 50.3015H116.615Z"/>
    </svg>
  )
}

export default CountryCodeFIN