import React from 'react';

function Logo(props) {
	return (
		<svg width="163" height="26" viewBox="0 0 163 26" xmlns="http://www.w3.org/2000/svg">
<path d="M2.7908 0.585938V12.5158L15.4889 0.585938H19.3786L8.1718 11.1708L19.4833 25.4063H15.8727L6.11359 13.1533L2.7908 16.2711V25.4063H0V0.585938H2.7908Z" fill={props.fill || 'black'} />
<path d="M27.2277 15.2055L31.754 14.4981V13.6859C31.754 11.8082 30.3761 10.3236 27.6899 10.3236C25.0387 10.3236 23.591 11.8432 23.591 14.2187C23.591 14.3933 23.591 14.5418 23.6258 14.7165L20.9048 14.5069V14.1139C20.9048 9.97422 24.0183 7.84326 27.5853 7.84326C32.1116 7.84326 34.4838 10.2188 34.4838 13.8955V25.3712H31.7628V23.1442C30.847 24.6638 29.1813 25.869 26.6696 25.869C22.5357 25.869 20.8002 23.249 20.8002 20.8386C20.7914 17.616 23.0939 15.8431 27.2277 15.2055ZM27.1928 23.2839C29.8441 23.2839 31.754 21.2665 31.754 18.1138V16.8736L27.4021 17.616C25.3875 17.9653 23.5474 18.6814 23.5474 20.594C23.5474 22.2534 24.9602 23.2839 27.1928 23.2839Z" fill={props.fill || 'black'} />
<path d="M41.8358 0.585938V25.3714H39.0101V0.585938H41.8358Z" fill={props.fill || 'black'} />
<path d="M49.1878 0.585938V25.3714H46.3621V0.585938H49.1878Z" fill={props.fill || 'black'} />
<path d="M59.5138 15.2055L64.0401 14.4981V13.6859C64.0401 11.8082 62.6621 10.3236 59.976 10.3236C57.3247 10.3236 55.877 11.8432 55.877 14.2187C55.877 14.3933 55.877 14.5418 55.9119 14.7165L53.1909 14.5069V14.1139C53.1909 9.97422 56.3043 7.84326 59.8713 7.84326C64.3977 7.84326 66.7698 10.2188 66.7698 13.8955V25.3712H64.0488V23.1442C63.1331 24.6638 61.4673 25.869 58.9556 25.869C54.8217 25.869 53.0862 23.249 53.0862 20.8386C53.0775 17.616 55.3712 15.8431 59.5138 15.2055ZM59.4789 23.2839C62.1301 23.2839 64.0401 21.2665 64.0401 18.1138V16.8736L59.6882 17.616C57.6736 17.974 55.8334 18.6814 55.8334 20.594C55.8334 22.2534 57.2462 23.2839 59.4789 23.2839Z" fill={props.fill || 'black'} />
<path d="M74.1131 8.376V10.5332C75.3167 8.90001 77.1917 7.84326 79.7383 7.84326C83.4536 7.84326 85.6426 10.3236 85.6426 14.2885V25.3712H82.8169V14.8911C82.8169 11.913 81.5436 10.4284 78.8924 10.4284C76.0318 10.4284 74.1218 12.6554 74.1218 16.2361V25.3712H71.2962V8.376H74.1131Z" fill={props.fill || 'black'} />
<path d="M130.679 0.0515137C136.269 0.0515137 140.299 3.34401 141.249 8.26966L138.528 9.012C137.857 5.29156 134.961 2.77634 130.679 2.77634C125.089 2.77634 121.278 7.16925 121.278 12.5141C121.278 18.8546 125.167 23.1689 131.037 23.1689C135.598 23.1689 139.313 20.2694 139.767 15.8066L142.592 16.3044C141.956 21.9374 137.08 25.9024 131.063 25.9024C123.458 25.9024 118.443 20.4528 118.443 13.0468C118.452 5.7195 123.292 0.0515137 130.679 0.0515137Z" fill={props.fill || 'black'} />
<path d="M153.956 7.84155C159.119 7.84155 162.651 11.8415 162.651 16.9068C162.651 21.9722 159.119 25.9023 153.956 25.9023C148.723 25.9023 145.191 21.9722 145.191 16.9068C145.183 11.8415 148.723 7.84155 153.956 7.84155ZM153.921 23.1687C157.349 23.1687 159.825 20.5487 159.825 16.8981C159.825 13.2475 157.349 10.5576 153.921 10.5576C150.494 10.5576 148.017 13.2475 148.017 16.8981C148.008 20.5137 150.45 23.1687 153.921 23.1687Z" fill={props.fill || 'black'} />
<path d="M106.757 13.3456C106.757 14.5421 106.46 16.1054 105.858 16.97L108.082 19.4503H106.094L104.969 18.1839C104.053 19.2319 102.64 19.6948 101.254 19.6948C99.0123 19.6948 97.1721 18.2625 97.1721 15.922C97.1721 14.5596 97.8698 13.3805 99.0123 12.6644L99.6577 12.2627C98.8117 11.3282 98.114 10.5684 98.114 9.20598C98.114 7.39816 99.5094 6.16675 101.402 6.16675C103.329 6.16675 104.725 7.34576 104.725 9.25838C104.725 10.4898 104.123 11.5029 103.05 12.1491C102.736 12.3325 102.422 12.5509 102.117 12.7343L104.821 15.791C105.239 15.0224 105.283 13.6775 105.283 12.6295V12.0007H108.074V13.3456H106.757ZM100.617 13.3107L99.867 13.791C99.1519 14.2714 98.742 15.0224 98.742 15.887C98.742 17.2669 99.806 18.2451 101.166 18.2451C102.309 18.2451 103.26 17.9918 103.975 17.066L100.617 13.3107ZM101.376 7.49423C100.347 7.49423 99.649 8.1929 99.649 9.18851C99.649 10.2365 100.495 10.9527 101.14 11.6688C102.169 11.1186 103.216 10.5859 103.216 9.27584C103.216 8.1929 102.553 7.49423 101.376 7.49423Z" fill={props.fill || 'black'} />
<path d="M102.274 1.31001C108.047 1.31001 112.74 6.52386 112.74 12.9342C112.74 19.3445 108.047 24.5584 102.274 24.5584C96.5006 24.5584 91.8086 19.3445 91.8086 12.9342C91.8086 6.52386 96.5093 1.31001 102.274 1.31001ZM102.274 0C95.768 0 90.5004 5.79025 90.5004 12.9342C90.5004 20.0781 95.768 25.8684 102.274 25.8684C108.78 25.8684 114.048 20.0781 114.048 12.9342C114.048 5.79025 108.78 0 102.274 0Z" fill={props.fill || 'black'} />
</svg>

	)
}

export default Logo