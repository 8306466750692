import React from 'react';

const CountryCodeAU = ({color}) => {
  return (
    <svg viewBox="0 0 212 137" style={{fill: color}}>
      <path fillRule="evenodd" clipRule="evenodd" d="M176.607 111.048C194.533 99.4385 204.432 84.2069 204.432 68.4024C204.432 52.5978 194.533 37.3663 176.607 25.7572C158.749 14.1921 133.671 6.81611 105.621 6.81611C77.5717 6.81611 52.4933 14.1921 34.6354 25.7572C16.7096 37.3663 6.81021 52.5978 6.81021 68.4024C6.81021 84.2069 16.7096 99.4385 34.6354 111.048C52.4933 122.613 77.5717 129.989 105.621 129.989C133.671 129.989 158.749 122.613 176.607 111.048ZM105.621 136.805C163.954 136.805 211.243 106.18 211.243 68.4024C211.243 30.6248 163.954 0 105.621 0C47.2883 0 0 30.6248 0 68.4024C0 106.18 47.2883 136.805 105.621 136.805Z"/>
      <path d="M82.8545 50.3022L68.0264 86.6263H74.248L78.1883 76.9226H94.2089L98.0974 86.6263H104.371L89.5427 50.3022H82.8545ZM91.9795 71.474H80.3658L86.1727 57.0481L91.9795 71.474Z"/>
      <path d="M111.656 50.3022L111.605 72.6156C111.605 81.2296 117.411 87.4046 126.329 87.4046C135.143 87.4046 140.95 81.2296 140.95 72.6156L140.898 50.3022H135.195L135.247 72.5637C135.247 78.3236 131.825 81.9041 126.329 81.9041C120.73 81.9041 117.308 78.2717 117.308 72.5637L117.36 50.3022H111.656Z"/>
    </svg>
  )
}

export default CountryCodeAU