import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ParallaxProvider } from 'react-scroll-parallax';
import App from './App';
import './index.scss';


ReactDOM.render(
	<Provider store={store}>
    <ParallaxProvider>
		<div className="page_wrap">
			<App />
		</div>
    </ParallaxProvider>
	</Provider>,
	document.getElementById('root')
);
