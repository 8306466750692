import React from 'react';
import Slicer from './Slicer';
import Fader from './Fader';
import CaseStudyHeader from './CaseStudyHeader';
import '../assets/style/CaseStudyContent.scss'

function CaseStudyContent(props) {
	const { content } = props;
	const { 
		body, 
		title, 
		intro, 
		categories, 
		case_study_header_image,
		header_image_background_colour,
		work_page_cover,
		industry_list,
		service_list,
		spacing,
		rounding
	} = content;

	return (
		<div className={`case_study_wrap ${rounding === 'on' ? 'rounding' : ''} ${spacing === 'small' ? 'spacing-small' : 'spacing-large'}`}>
			<CaseStudyHeader
				title={title}
				intro={intro}
				categories={categories}
				image={case_study_header_image}
				workPageImage={work_page_cover}
				industryList={industry_list}
				serviceList={service_list}
				headerImageBackgroundColor={header_image_background_colour}
			/>
			{body && body.map((slice, idx) =>
				<Fader key={idx}> 
					<div className="case_block">
						<Slicer
							type={slice.slice_type}
							content={slice}
						/>
					</div>
				</Fader>
			)}
			<div>

			</div>
		</div>
	)
}

export default CaseStudyContent