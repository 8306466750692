import React from 'react';
import { connect } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';
import { withLastLocation } from 'react-router-last-location';
import { MultilineReveal } from "react-text-reveal";
import { InView } from 'react-intersection-observer'
import Fade from 'react-reveal/Fade';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Fader from '../components/Fader';
import SrcsetImage from '../components/SrcsetImage';
import ScrollToTop from '../components/ScrollToTop';
//import { handleLineBreaks } from '../utils/prismic';
import '../assets/style/About.scss';


class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        locale: false,
        pageReady: false
    }
  }

  componentDidMount() {
    if (!!this.props.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }
    this.timeoutId = setTimeout(function () {
        this.setState({
            pageReady: true
        });
    }.bind(this), 30);
  }

  componentDidUpdate(prevProps) {
    if (!this.state.locale && !!prevProps.data.locale) {
      this.setState({
        locale: this.props.data.locale
      })
    }

  }

  render() {

    const { aboutPage } = this.props.data;
    const { intro,
            photo_1,
            photo_2,
            photo_3,
            photo_4,
            client_logos
    } = aboutPage;
    const { lastLocation } = this.props;
    const { pageReady } = this.state;

    const ParallaxImage1 = () => (
        <Parallax y={[-10, 5]} tagOuter="figure">
            <SrcsetImage key={photo_1.url} data={photo_1} lazy={false}/>
        </Parallax>
    );
    const ParallaxImage2 = () => (
        <Parallax y={[0, 15]} tagOuter="figure">
            <SrcsetImage key={photo_2.url} data={photo_2} lazy={false}/>
        </Parallax>
    );
    const ParallaxImage3 = () => (
        <Parallax y={[-20, 25]} tagOuter="figure">
            <SrcsetImage key={photo_3.url} data={photo_3} lazy={false} />
        </Parallax>
    );
    const ParallaxImage4 = () => (
        <Parallax y={[-10, 35]} tagOuter="figure">
            <SrcsetImage key={photo_4.url} data={photo_4} lazy={false} />
        </Parallax>
    );


    return (
      <div className={"about " + (pageReady? "loaded":"")}>
        {lastLocation && !lastLocation.pathname.includes('work') && <ScrollToTop />}
        <SEO
          title="About"
          description={intro}
        />
        <Header />


            <InView className="hero inner" as="div" triggerOnce onChange={
                (inView, entry) => {
                  if (inView) this.setState({heroInView: true})
                }
              }>

              <Fade bottom duration={700} distance="40px" delay={10}><p className="identifier">Strategic design studio</p></Fade>

              <h1 className="bravo desktop">
                <MultilineReveal
                    animateOpacity={true}
                    canPlay={this.state.heroInView}
                    copy={["Envision the future,","shape outcomes,", "accelerate progress"]}
                    direction={"bottom"}
                    duration={700} // ms
                    ease={"cubic-bezier(0.12,0.66,0.29,0.96)"}
                    offset={"100px"}
                    multilineMasking={true}
                    multilineOffsetDelay={75} // ms
                />
              </h1>
            </InView>


        <Fade bottom duration={600} distance="60px" delay={160}>
        <div className="gallery first inner">
            <div className="photo1"><ParallaxImage1 /></div>
            <div className="photo2"><ParallaxImage2 /></div>
            <div className="photo3"><ParallaxImage3 /></div>
            <div className="photo4"><ParallaxImage4 /></div>
        </div>
        </Fade>

        <Fader>
          <div className="inner">
            <p class="worktitle">What we do</p>
          </div>
        </Fader>


        <Fader>
        <div className="worklist inner">

          <div className="workitem">
              <h2>Strategy & Insight</h2>
              {/*<p>{handleLineBreaks(ethos_description)}</p> */}
              <p>Meeting each business critical situation with visionary ideas and break-through insights for tomorrow</p>
              <ul>
                  <li>Brand & Experience Strategy</li>
                  <li>Vision & Ambition</li>
                  <li>Portfolio & Architecture Strategy</li>
                  <li>Channel Strategy</li>
                  <li>Customer Research</li>
                  <li>Creative Process Facilitation </li>
                  <li>Category Design</li>
                  <li>Market Research</li>
                  <li>End-user Testing & Insights</li>
              </ul>
          </div>

          <div className="workitem">
              <h2>Brand Design</h2>
              {/*<p>{handleLineBreaks(ethos_description)}</p> */}
              <p>Creating entirely bespoke design experiences at the intersection of creativity and tech innovation</p>
              <ul>
                  <li>Visual Identity</li>
                  <li>Verbal Identity & Tone of Voice</li>
                  <li>Illustration</li>
                  <li>Art Direction & Photography</li>
                  <li>Motion Identity</li>
                  <li>Naming</li>
                  <li>Brand Launch</li>
                  <li>Brand Films</li>
              </ul>
          </div>

          <div className="workitem">
              <h2>Digital Product Design</h2>
              {/*<p>{handleLineBreaks(ethos_description)}</p> */}
              <p>Leveraging emerging behaviours and technologies to build digital experiences that ignite growth</p>
              <ul>
                  <li>Digital Product Design</li>
                  <li>CX Design</li>
                  <li>User Interface Design</li>
                  <li>Website Design</li>
                  <li>Component Libraries</li>
                  <li>Concept Design</li>
                  <li>High-fidelity Prototyping</li>
                  <li>Innovation Ideas</li>
                  <li>Accessibility</li>
              </ul>
          </div>

          <div className="workitem">
              <h2>Production Support</h2>
              {/*<p>{handleLineBreaks(ethos_description)}</p> */}
              <p>Partnering with ambitious businesses to scale-up and enhance their teams, processes and competencies </p>
              <ul>
                  <li>Product Design & Maintenance</li>
                  <li>Brand Roll-Out & Training</li>
                  <li>Campaign Creation & Support</li>
                  <li>Marketing Support</li>
                  <li>Website Development</li>
                  <li>Motion Design</li>
                  <li>Illustration</li>
              </ul>
          </div>



        </div>
        </Fader>


        <div className="client_logos inner">
          <div className="logolist">
            {client_logos.map((item, index) => (
              <img
                key={index}
                src={item.client_logo.url}
                alt={item.client_logo.alt}
              />
            ))}
          </div>
        </div>

        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    locale: state.locale
  }
}

export default connect(mapStateToProps)(withLastLocation(About));